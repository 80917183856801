<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">
          Loading ...
        </span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">
          {{ dt.id }}
        </a>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.description }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.rate }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.range }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.is_active ? "ACTIVATED" : "DISABLED" }}
        </span>
      </td>
      <td>
        <span class="font-weight-normal">
          {{ dt.created_at }}
        </span>
      </td>
      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <router-link :to="`/cards/entries/${dt.id}`" class="dropdown-item">
              <span class="fas fa-edit mr-1"></span>
              Edit Entry
            </router-link>
            <a
              href="#"
              class="dropdown-item text-danger"
              :class="dt.is_active ? 'text-danger' : 'text-dark'"
              @click="
                RequestConfirmation(
                  `Are you sure you want to ${
                    dt.is_active ? 'disable' : 'enable'
                  } this entry?`,
                  ToggleStatus
                )
              "
            >
              <template v-if="dt.is_active">
                <span class="fas fa-times mr-1"></span>
                Disable
              </template>
              <template v-else>
                <span class="fas fa-check mr-1"></span>
                Enable
              </template>
            </a>
            <a
              href="#"
              class="dropdown-item text-danger"
              @click="
                RequestConfirmation(
                  'Are you sure you want to delete this entry? This action is irreversible',
                  DeleteEntry
                )
              "
            >
              <span class="fas fa-trash-alt mr-1"></span>
              Delete Entry
            </a>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { inject, ref, defineComponent } from "vue";
import { apiDelete, apiPut } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "GiftCardEntryRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete", "statusChanged"],
  setup(props, context) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const DeleteEntry = async () => {
      loading.value = true;
      try {
        await apiDelete(`/admin/cards/entry/${props.dt.id}`);
        ShowSuccess();
        context.emit("delete");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ToggleStatus = async () => {
      loading.value = true;
      try {
        await apiPut(`/admin/cards/entry/${props.dt.id}`, {
          is_active: !props.dt.is_active,
        });
        ShowSuccess();
        context.emit("statusChanged");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle status: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      DeleteEntry,
      ToggleStatus,
    };
  },
});
</script>

<style scoped></style>
