<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">
            Gift Card Entries for
            {{ route.query.uid ?? route.params.giftCardId }}
          </h2>
          <p class="mb-0">
            Find below the available gift card entries for
            {{ route.query.uid ?? route.params.giftCardId }}
          </p>
        </div>

        <div class="btn-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="RefreshData"
          >
            Reload
          </button>
        </div>
      </div>
      <div class="table-settings mb-4">
        <div class="row align-items-center justify-content-between">
          <div class="col col-md-6 col-lg-3 col-xl-4">
            <router-link
              :to="`/cards/${route.params.giftCardId}/enter`"
              class="btn btn-dark"
              >Create new Entry</router-link
            >
          </div>
          <div v-if="data" class="col-4 col-md-2 col-xl-1 pl-md-0 text-right">
            <CountSelector
              v-model="data.meta.per_page"
              @updated="RefreshData"
            />
          </div>
        </div>
      </div>
      <div
        class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0"
      >
        <table class="table table-hover min-h-400 align-items-center">
          <thead>
            <tr>
              <th>#</th>
              <th>Card Type</th>
              <th>Rate</th>
              <th>Range/Denomination</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody v-if="data">
            <GiftCardEntryRow
              v-for="(dt, index) in data.entries"
              :key="index"
              :dt="dt"
              @delete="RefreshData"
              @statusChanged="dt.is_active = !dt.is_active"
            />
          </tbody>
        </table>
        <Pagination
          v-if="data"
          v-model="data.meta.page"
          :currentPage="data.meta.page"
          :totalRecord="data.meta.count"
          :dataCount="data.meta.per_page"
          @updated="RefreshData"
        />
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import GiftCardEntryRow from "@/views/cards/constituent/GiftCardEntryRow.vue";

export default defineComponent({
  name: "AvailableGiftCards",
  components: {
    GiftCardEntryRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/${route.params.giftCardId}/entries`,
          (data.value as any)?.meta ?? {}
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
    };
  },
});
</script>

<style scoped></style>
