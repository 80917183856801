
import { inject, ref, defineComponent } from "vue";
import { apiDelete, apiPut } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "GiftCardEntryRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete", "statusChanged"],
  setup(props, context) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    const DeleteEntry = async () => {
      loading.value = true;
      try {
        await apiDelete(`/admin/cards/entry/${props.dt.id}`);
        ShowSuccess();
        context.emit("delete");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle admin type: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ToggleStatus = async () => {
      loading.value = true;
      try {
        await apiPut(`/admin/cards/entry/${props.dt.id}`, {
          is_active: !props.dt.is_active,
        });
        ShowSuccess();
        context.emit("statusChanged");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to toggle status: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      RequestConfirmation,
      DeleteEntry,
      ToggleStatus,
    };
  },
});
